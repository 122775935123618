$(document).ready(function() {
    $('#resources-table').DataTable({
        "info": false,
       // "lengthChange": false,   
        "language": {
            "paginate": {
              "previous": "Prev"              
            },           

        },
        responsive: true,
        columnDefs: [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: -1 }
        ]
     
    });
} );