$(document).ready(function() {
    var img = $('.video-col .img-wrap, .video-col .overlay');
    img.click(function() {
        var iframe = $(this).parent().find('iframe');
        iframe.attr('src', iframe.attr('src')+'&autoplay=1');   
        var overlay = iframe.siblings('.overlay');
        var imgCol = iframe.siblings('.img-wrap');
        overlay.fadeOut(500);
        imgCol.fadeOut(2000);
    });
    var txt = img.parent().parent().parent().find('.txt-col');
    var icon = txt.find('.icon.video');
    icon.click(function() {
        var iframe = $(this).parent().parent().parent().find('iframe');
        iframe.attr('src', iframe.attr('src')+'&autoplay=1');
        var overlay = iframe.siblings('.overlay');
        var imgCol = iframe.siblings('.img-wrap');
        overlay.fadeOut(500);
        imgCol.fadeOut(2000);
    });
});
