import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.$ = $;

require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

// $(document).foundation();


require ('./vendor/slick.min');
require ('./vendor/modernizr.js');
require ('./vendor/datatables.min.js');
// require ('./vendor/dataTables.responsive.min.js');

require ('./parts/archive_filter');
require ('./parts/heroslider');
require ('./parts/scrolled');
require ('./parts/toggles');


require ('./pagebuilder/slideshow');
require ('./pagebuilder/testimonialslider');
require ('./pagebuilder/logocarousel');
require ('./pagebuilder/videos'); 
require ('./pagebuilder/resourcestable'); 
require ('./pagebuilder/map'); 
$(document).foundation();


// require('./parts/cards');
