// Testimonial Slider
// ------------------------------------------
function testimonialSlider() {
  jQuery('.testimonial-slider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true
  });

  $('.testimonials--controls-prev').click(function(){
    $('.testimonial-slider').slick('slickPrev');
  });

  $('.testimonials--controls-next').click(function(){
    $('.testimonial-slider').slick('slickNext');
  });
}

testimonialSlider();
