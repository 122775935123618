
  // global var
var map = null;
$(document).ready(function(){
    $('.acf-map').each(function(){
      // create map
      map = new_map($(this));
    });
});

  /*
  *  new_map
  *
  *  This function will render a Google Map onto the selected jQuery element
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param $el (jQuery element)
  *  @return  n/a
  */

 function new_map($el) {
    // var
    var $markers = $el.find('.marker');

    // vars
    var args = {
      zoom    : 10,
      center    : new google.maps.LatLng(0, 0),
      mapTypeId : google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      styles: [{"featureType":"administrative",
      "elementType":"geometry.fill",
      "stylers":[{"color":"#8b8b8b"},{"saturation":"0"}]},
      {"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#b4934a"}]},
      {"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#b4934a"}]},
      {"featureType":"administrative.land_parcel","elementType":"geometry.fill","stylers":[{"color":"#ff0000"}]},
      {"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},
      {"featureType":"landscape","elementType":"geometry.fill","stylers":[{"color":"#b4934a"},{"lightness":"85"}]},
      {"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},
      {"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#b4934a"}]},
      {"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#b4934a"}]},
      {"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},
      {"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#b4934a"},{"lightness":"60"}]},
      {"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#b4934a"}]},
      {"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"},{"lightness":"0"},{"weight":"3.19"},{"saturation":"0"},{"gamma":"1.04"}]},
      {"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},
      {"featureType":"road.highway.controlled_access","elementType":"geometry.fill","stylers":[{"color":"#b4934a"},{"lightness":"60"}]},
      {"featureType":"road.highway.controlled_access","elementType":"labels.text.fill","stylers":[{"color":"#b4934a"}]},
      {"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#b4934a"},{"lightness":"60"}]},
      {"featureType":"road.arterial","elementType":"labels.text.fill","stylers":[{"color":"#b4934a"}]},
      {"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},
      {"featureType":"road.local","elementType":"geometry.fill","stylers":[{"color":"#b4934a"},{"lightness":"60"}]},
      {"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#b4934a"}]},
      {"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"},{"color":"#ff0000"}]},
      {"featureType":"water","elementType":"all","stylers":[{"color":"#b48620"},{"visibility":"on"}]},
      {"featureType":"water","elementType":"geometry.fill","stylers":[{"lightness":"100"},{"saturation":"80"},{"color":"#b4934a"},{"gamma":"1.70"}]},
      {"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#b4934a"}]}]
    };

    // create map
    var map = new google.maps.Map( $el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function(){
        add_marker( $(this), map );
    });

    // center map
    center_map( map );

    // return
    return map;
  }


  // =================================================
// Google Maps
// =================================================

  /*
  *  add_marker
  *
  *  This function will add a marker to the selected Google Map
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param $marker (jQuery element)
  *  @param map (Google Map object)
  *  @return  n/a
  */

 function add_marker( $marker, map ) {

    // var
    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
    var imgPath = add_pin.template_directory_uri+'/images/pin.svg';
    // create marker
    var marker = new google.maps.Marker({
      position : latlng,
      map : map,
      title: 'PIN',
      icon: imgPath,
    });

    // add to array
    map.markers.push( marker );

    // if marker contains HTML, add it to an infoWindow
    if( $marker.html() )
    {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content   : $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function() {

        infowindow.open( map, marker );

      });
    }

  }

  /*
  *  center_map
  *
  *  This function will center the map, showing all markers attached to this map
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param map (Google Map object)
  *  @return  n/a
  */

  function center_map( map ) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each( map.markers, function( i, marker ){

      var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

      bounds.extend( latlng );

    });

    // only 1 marker?
    if( map.markers.length == 1 )
    {
      // set center of map
        map.setCenter( bounds.getCenter() );
        map.setZoom( 10 );
    }
    else
    {
      // fit to bounds
      map.fitBounds( bounds );
    }

  }
