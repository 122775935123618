function logocarousel(){
$('.logo-carousel').slick({
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 5,
  adaptiveHeight: true,
  centerMode: false,
  centerPadding: '5px',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        centerMode: false,
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: false,
      }
    },
    {
      breakpoint: 475,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    }
  ]
});
}

logocarousel();
