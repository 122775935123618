$(document).ready(function() {
    $('#main-menu > ul > li').click(function() {
        if($(window).width() < 1064) {
            $(this).toggleClass('active');
            // return false;
        }
    });
    $('#main-menu > ul > li > a').click(function(e) {
        e.stopPropagation();
    });
});
