// Hero Slider
// ------------------------------------------
function heroSlider() {
    jQuery('.hero-slider').slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        fade: true,
        draggable: false,
        autoplay: true,
        autoplaySpeed: 4000
        // adaptiveHeight: true
    });

    $('.hero-slider nav button.prev').click(function(){
        $('.hero-slider').slick('slickPrev');
    });

    $('.hero-slider nav button.next').click(function(){
        $('.hero-slider').slick('slickNext');
    });
}

heroSlider();
