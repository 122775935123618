// Image Slider
// ------------------------------------------
function imageSlider() {
  jQuery('.image-slider').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    autoplay: true,
	  autoplaySpeed: 5000,
  });

  $('.image-slider--controls-prev').click(function(){
    $('.image-slider').slick('slickPrev');
    $('.image-slider').slick('slickPause');
  });

  $('.image-slider--controls-next').click(function(){
    $('.image-slider').slick('slickNext');
    $('.image-slider').slick('slickPause');
  });
}

imageSlider();
